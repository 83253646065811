const appEnv = {
  ENV: 'prod',
  NAMESPACE: '',
  LOGO_IMAGE: '/assets/images/logo.png',
  WEB_ENDPOINT: 'https://fulldepth.co.jp',
  CMS_ENDPOINT: 'https://cms.fulldepth.co.jp',
  API_ENDPOINT: 'https://cms-api.fulldepth.co.jp/api/v1',
  AUTH_ENDPOINT: 'https://cms-api.fulldepth.co.jp/auth',
};

export default appEnv;
